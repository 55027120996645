import axios from "@/plugins/axios";
import { formatSpreadsheetFile } from "@/utils/ExportFileType";

/**
 *
 * @param ids Array of string ids
 * @param exportAll Prevent from sending an exceed amount of ids
 * @param props
 * @param routeApi Controller Api to use
 * @param context
 * @return {Promise<unknown>}
 */
export default async ({ ids, exportAll }, routeApi, context, props = {}) => {
    const dtoExportSheet = {
        ...props,
        importSheetDto: {
            keys: exportAll ? [] : ids,
            exportAll
        }
    }
    return new Promise((resolve, reject) => {
        axios.api.post(routeApi, dtoExportSheet)
            .then(async (res) => {
                await formatSpreadsheetFile(res.data.data)
                resolve(true)
            })
            .catch(() => {
                context.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur s\'est produite', life: 3000});
                reject(true)
            })
    })
}
