<template>
  <div class="flex-container non-scrollable">
    <DataTable ref="dt" :value="items" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.missions')})"
               scrollable scroll-height="flex" selection-mode="single" :sort-field="sortByEndingDate" :sort-order="1" @rowSelect="clickPorte">
      <template #header>
        <div class="p-d-flex flex justify-content-between ml-2">
          <div class="flex flex-row gap-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
            </span>
            <div v-if="enableArchivedMissionSelector" class="flex align-items-center ml-2">
              <Checkbox class="mr-2" v-model="showArchivedMission" :binary="true"/>
              <span>Afficher les missions archivées</span>
            </div>
          </div>
          <div class="flex flex-row gap-2">
            <!--            <Button v-if="false" class="h-auto" icon="pi pi-plus" :label="`Créer ${isSimulation ? 'simulation' : 'mission'}`" @click="startCreateItem" />-->
            <Button :disabled="missions.length === 0" :class="{ 'disabled-button': missions.length === 0 }"
                    :loading="exportSheetLoading" icon="pi pi-external-link" :label="$t('export')" size="small"
                    @click="createSheet"/>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column v-if="fields.includes('indepLastName')" field="independent.last_name" :header="$t('lastname').toUpperCase()"
              sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('indepFirstName')" field="independent.first_name" :header="$t('firstname').toUpperCase()"
              sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('indepJob')" field="independent.job" :header="$t('mission').toUpperCase()"
              sortable filterField="independent.job" sortField="independent.job" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('mission') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="jobsFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('tjm')" field="jsonMission.tjm" :header="$t('dailyRate').toUpperCase()"
              sortable sortField="jsonMission.tjm" :showFilterMatchModes="false" class="set-column">
      </Column>
      <Column v-if="fields.includes('date_created')" field="date_created" :header="$t('createdDate').toUpperCase()"
              sortable filterField="date_created" sortField="date_created" :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{getDateFormatted(data.date_created, {day: '2-digit', month: '2-digit', year: 'numeric'})}}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('createdDate') }}</div>
          <Calendar @date-select="filterCallback()" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="mm/dd/yyyy" />
        </template>
      </Column>
      <Column v-if="fields.includes('clientContract')" field="clientContract.name" :header="$t('clientContract').toUpperCase()"
              sortable filterField="clientContract.name" sortField="clientContract.name" :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('clientContract') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="clientContractsFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('mission')" field="jsonMission.jobTitle" :header="$t('mission').toUpperCase()"
              sortable filterField="jsonMission.jobTitle" sortField="jsonMission.jobTitle" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Client final</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="missionsFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('dateStart')" field="jsonMission.startingDate" :header="$t('beginDate').toUpperCase()"
              sortable filterField="jsonMission.startingDate" sortField="jsonMission.startingDate" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false">
        <template #body="{data}">
          {{getDateFormatted(data?.jsonMission?.startingDate, {day: '2-digit', month: '2-digit', year: 'numeric'})}}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown v-model="filterModel.matchMode" :options="FilterMatchModeCustomOptions()"
                    option-value="value" option-label="label" class="mb-2" @update:model-value="filterCallback()"/>
          <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" :manual-input="false"
                    :showOnFocus="false" show-icon @date-select="filterCallback()"/>
        </template>
      </Column>
      <Column v-if="fields.includes('endContract')" field="jsonMission.initialEndingDate" :header="$t('endContract').toUpperCase()"
              sortable filterField="jsonMission.initialEndingDate" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :sort-field="sortByEndingDate">
        <template #body="{data}">
          <span :style="'color: ' + getEndContractColor(data)">
            {{getDateFormatted(getContractEndingDate(data), {day: '2-digit', month: '2-digit', year: 'numeric'})}}
          </span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown v-model="filterModel.matchMode" :options="FilterMatchModeCustomOptions()"
                    option-value="value" option-label="label" class="mb-2" @update:model-value="filterCallback()"/>
          <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" :manual-input="false"
                    :showOnFocus="false" show-icon @date-select="filterCallback()"/>
        </template>
      </Column>
      <Column v-if="fields.includes('averageDailyRate')" field="independent.email" :header="$t('averageDailyRate').toUpperCase()"
              sortable sortField="independent.email" class="set-column">
        <template #body="{data}">
          {{ getAverageDailyRate(data) }}
        </template>
      </Column>
      <Column v-if="fields.includes('indepPhone')" field="independent.phone" :header="$t('phone').toUpperCase()"
              sortable filterField="independent.phone" sortField="independent.phone" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{data.independent?.phone?.replaceAll('-', '.')}}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Téléphone</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="phonesFilter" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('renewal')" field="jsonMission.renewal" :header="$t('renewal').toUpperCase()"
              sortable filterField="jsonMission.renewal" sortField="jsonMission.renewal" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{ constants.data.renewalOptions.find(renewalOption => renewalOption.value === data.jsonMission.renewal)?.label ?? '' }}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('renewal') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.renewalOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('commercialFullName')" field="commercial.fullName" :header="$t('salesManager').toUpperCase()"
              sortable filterField="commercial.fullName" sortField="commercial.fullName" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Chargé commercial</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commercialFullNamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('contractType')" field="contractType" :header="$t('contractType').toUpperCase()"
              sortable filterField="contractType" sortField="contractType" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{ getOptionLabel(constants.data.contractTypeOptions, data.contractType) }}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Type de contrat</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.contractTypeOptions" option-value="value"
                       optionLabel="label" :placeholder="$t('all')" class="p-column-filter"
                       style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="fields.includes('status')" field="independent.status" :header="$t('status').toUpperCase()"
              sortable filterField="independent.status" sortField="independent.status" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.independent?.status"
                :label="getOptionLabel(constants.data.independentOptions, data.independent?.status)?.toUpperCase()"/>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Statut</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.independentOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span :class="'badge status-' + slotProps.option.value">{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column v-if="isDeletable" header="Actions" class="set-column">
        <template #body="{ data }">
          <Button v-if="data.status !== constants.MISSION_STATUS.ARCHIVED" severity="danger" type="button" size="small" rounded
                  icon="fa-regular fa-box-archive fa-sm"
                  @click="deleteMissionConfirmation(data)"/>
        </template>
      </Column>
    </DataTable>
<!--    <Dialog v-model:visible="dialogSelectIndependent" header="Choisissez un porté" :draggable="false" :modal="true" :closable="false" style="width: 50vw">-->
<!--      &lt;!&ndash; todo: this code should auto refactor to the good placement on the merge &ndash;&gt;-->
<!--      <div class="grid formgrid p-fluid">-->
<!--        <div class="field mb-3 col-12 md:col-6">-->
<!--          <label class="font-bold" for="independent">Porté</label>-->
<!--          <div>-->
<!--            <Dropdown id="independent" v-model="selectedIndependent"-->
<!--                      :options="independentFromMission" :option-label="(val) => val.first_name + ' ' + val.last_name">-->
<!--              <template #value="slotProps">-->
<!--                <span v-if="slotProps.value">-->
<!--                  {{ slotProps.value?.first_name + ' ' + slotProps.value?.last_name }}-->
<!--                </span>-->
<!--                <span v-else style="visibility: hidden">hidden content</span>-->
<!--              </template>-->
<!--            </Dropdown>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <template #footer>-->
<!--        <Button label="Fermer" icon="pi pi-times" @click="dialogSelectIndependent = false" text />-->
<!--        <Button label="Valider" icon="pi pi-check" @click="createMission" autofocus />-->
<!--      </template>-->
<!--    </Dialog>-->
    <dialog-confirm
      ref="dialog-confirm"
      :is-active="confirmDialog"
      :refuse-action="confirmDialogRefuse"
      :validate-action="confirmDialogValidate"
      :title="confirmDialogTitle"
      :text-info="confirmDialogInfo"
      :text-question="confirmDialogQuestion"
    />
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {mapGetters, mapState} from 'vuex'
import {
  callbackObjectColors,
  callbackObjectSort,
  getContractEndingDate,
  getEndContractAction,
  getOptionLabel
} from "@/utils/Misc";
import {getDateFormatted} from "@/utils/Date";
import {FilterMatchModeCustom, FilterMatchModeCustomOptions} from "@/utils/FiltersRegistration";
import SheetGenerator from "@/utils/SheetGenerator";
import DialogConfirm from "@/components/DialogConfirm.vue";

export default {
  name: "TableauPorte",
  components: {DialogConfirm},
  emits: ['reload'],
  props: {
    fields: {
      type: Array,
      required: true
    },
    isSimulation: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: false
    },
    enableArchivedMissionSelector: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customers: null,
      selectedCustomers: null,
      filters: {
        'global': {value: null, matchMode: null},
        'independent.job': {value: null, matchMode: FilterMatchMode.IN},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.email': {value: null, matchMode: FilterMatchMode.IN},
        'independent.phone': {value: null, matchMode: FilterMatchMode.IN},
        'commercial.fullName': {value: null, matchMode: FilterMatchMode.IN},
        'clientContract.name': {value: null, matchMode: FilterMatchMode.IN},
        'jsonClientFinal.name': {value: null, matchMode: FilterMatchMode.IN},
        'jsonMission.jobTitle': {value: null, matchMode: FilterMatchMode.IN},
        'jsonMission.renewal': {value: null, matchMode: FilterMatchMode.IN},
        'contractType': {value: null, matchMode: FilterMatchMode.IN},
        'date_created': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED},
        'jsonMission.startingDate': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED},
        'jsonMission.initialEndingDate': {value: null, matchMode: FilterMatchModeCustom.DATE_BEFORE_INCLUDED},
        'independent.status': {value: null, matchMode: FilterMatchMode.IN},
      },
      loading: true,
      exportSheetLoading: false,
      showArchivedMission: false,

      dialogSelectIndependent: false,
      selectedIndependent: null,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},
      confirmDialogRefuse: () => {}
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    ...mapGetters({
      missions: 'mission/getMissions',
      simulations: 'simulation/getSimulations'
    }),
    items () {
      if (this.isSimulation) {
        return this.simulations.map((simulation) => {
          return { ...simulation, commercial: { ...simulation.commercial, fullName: `${simulation.commercial.first_name} ${simulation.commercial.last_name}` }}
        })
      } else {
        return this.missions.filter((mission) => this.showArchivedMission ?
            mission.status === this.constants.MISSION_STATUS.ARCHIVED : // || mission.status !== this.constants.MISSION_STATUS.ARCHIVED
            mission.status !== this.constants.MISSION_STATUS.ARCHIVED
        )
      }
    },
    independentFromMission () {
      return this.missions.map((mission) => {
        return {
          ...mission.independent
        }
      })
    },
    lastnamesFilter () {
      let array = this.items.map((m) => m.independent.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.items.map((m) => m.independent.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    jobsFilter () {
      let array = this.missions.map((m) => m.independent.job)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    emailsFilter () {
      let array = this.items.map((m) => m.independent.email)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    phonesFilter () {
      let array = this.items.map((m) => m.independent.phone)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item,
            label: item?.replaceAll('-', '.')
          })
        }
      })
      return filterArray
    },
    commercialFullNamesFilter () {
      let array = this.items.map((m) => m.commercial.fullName)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    clientContractsFilter () {
      let array = this.missions.map((m) => m.clientContract.name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    clientFinalsFilter () {
      let array = this.missions.map((m) => m.jsonClientFinal ? m.jsonClientFinal.name : '')
      let filterArray = []
      array.forEach((item) => {
        if (item !== '' && !filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    missionsFilter () {
      let array = this.missions.map((m) => m.jsonMission.jobTitle)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    }
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    FilterMatchModeCustomOptions() {
      return FilterMatchModeCustomOptions
    },
    getEndContractAction,
    getDateFormatted,
    getOptionLabel,
    getContractEndingDate,
    callbackObjectColors,
    callbackObjectSort,
    sortByEndingDate (mission) {
      return this.getEndContractAction({ value: getContractEndingDate(mission), renewalValue: mission?.jsonMission?.renewal }, this.constants, this.callbackObjectSort())
    },
    getEndContractColor (mission) {
      return this.getEndContractAction({ value: getContractEndingDate(mission), renewalValue: mission?.jsonMission?.renewal }, this.constants, this.callbackObjectColors())
    },
    clickPorte (porte) {
      if (this.$route.name === 'CommercialPortes'){
        this.$router.push({ name: 'FicheMissionPorte', params: { idPorte: porte.data.independent.id, idMission: porte.data.id }})
      } else if (this.$route.name === 'Mission') {
        this.$router.push({ name: 'CreationMissionPorte', params: { idMission: porte.data.id }})
      } else if (this.$route.name === 'Prospects') {
        this.$router.push({ name: 'FicheProspect', params: { idProspect: porte.data.independent.id, idSimulation: porte.data.id } })
      }
    },
    // async startCreateItem () {
    //   if (this.isSimulation) {
    //     await this.$router.push({name: 'Simulation'})
    //   }
    //   this.dialogSelectIndependent = true
    // },
    // async createMission () {
    //   // todo: route this to the createMission page only with the idPorte and no mission id to consider the view as a created panel
    //   // if (this.$route.name === 'Mission') {
    //   //   this.$router.push({name: 'CreationMissionPorte', params: { idPorte: this.selectedIndependent.id }})
    //   // }
    //   // this.dialogSelectIndependent = false
    // },
    getAverageDailyRate (prospect) {
      let totalBase = 0
      prospect.jsonMissions.missions.forEach((mission) => {
        totalBase += mission.base
      })
      return totalBase / prospect.jsonMissions.missions.length
    },
    async deleteMissionConfirmation (data) {
      // const context = this
      this.title = 'Supprimer la mission'
      this.confirmDialogInfo = 'Vous êtes sur le point d\'archiver la mission.'
      this.confirmDialogRefuse = () => {
        this.closeConfirmDialog()
      }
      this.confirmDialogValidate = async () => {
        this.closeConfirmDialog()
        // todo: need an alternative way for setTimeout
        setTimeout(async () => {
          await this.deleteMission(data)
        }, 200)
      }
      this.confirmDialog = true
    },
    async deleteMission (data) {
      try {
        await this.$store.dispatch('mission/deleteMissionProcess', { mission: data })
        // todo: should not reload, but change the store with the updated changes properly
        this.$emit('reload')
      } catch (e) {
        if (e.response.data.warning === 'invalidate-independent') {
          this.title = 'Passage du porté en prospect'
          this.confirmDialogInfo = 'Si vous supprimer la mission, le porté passera en prospect'
          this.confirmDialogRefuse = () => {
            this.closeConfirmDialog()
          }
          this.confirmDialogValidate = async () => {
            this.closeConfirmDialog()
            await this.$store.dispatch('mission/deleteMissionProcess', { mission: data, forceInvalidateIndependent: true })
            // todo: should not reload, but change the store with the updated changes properly
            this.$emit('reload')
          }
          this.confirmDialog = true
        }
      }
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    },
    async createSheet () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(porte => porte.id)
      SheetGenerator({ ids, exportAll: false }, '/mission/export-sheet', this)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep(.p-column-title) {
  font-size: 0.85rem;
}
</style>
